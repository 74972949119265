import type { CommerceLayerClient } from '@commercelayer/sdk'
import { toRefs } from '@vueuse/core'
import type { SizeData } from '@design-system/components/Pdp/PdpFitFinder.props'

export const useQuickBuy = async (product: Ref<string>) => {
  const { lineItems } = useCart()
  const { setCartEventsPayload } = useGACartEvents()
  const appConfig = useAppConfig()
  const algoliaIndex = appConfig.currentAlgoliaIndex
  const { $cl } = useNuxtApp()
  const { pdpPath } = useRouteHelper()

  const getSizeData = async (items: string[]) => {
    const data: Record<string, SizeData> = await $fetch(
      '/api/getSizesBySizeCode/getSizesByCode',
      {
        method: 'POST',
        body: { sizeCodes: items },
      }
    )
    return Object.keys(data).map((key: string) => {
      return data[key]
    })
  }

  // use async data to fetch multiple things at once, instead of useFetch
  const { data } = await useAsyncData(
    async () => {
      const algoliaProduct = await $fetch(
        `/api/getProduct/${algoliaIndex}/${product.value}`
      )

      if (!algoliaProduct) throw new Error('Error: product not found')
      setCartEventsPayload({ algoliaObjects: [algoliaProduct] })

      const skuList = algoliaProduct.size
        .map(item => item.SKU)
        .filter(skuCode => skuCode !== undefined)

      const { data: clPricesAndStocks } = await tryCatch(
        ($cl as CommerceLayerClient).skus.list({
          include: ['prices', 'stock_items', 'stock_items.reserved_stock'],
          filters: {
            code_in: skuList?.toString() ?? '',
          },
        })
      )

      const altColorProducts = algoliaProduct['alternative-color']?.length
        ? await $fetch(`/api/getProducts/${algoliaIndex}`, {
            query: {
              mfcList: algoliaProduct['alternative-color']
                .map(element => element.objectID)
                .join(','),
            },
          }).catch(() => null)
        : null

      return {
        algoliaProduct,
        altColorProducts,
        clPricesAndStocks,
      }
    },
    {
      watch: [product],
      transform: async ({
        algoliaProduct,
        altColorProducts,
        clPricesAndStocks,
      }) => {
        const algoliaAssets = algoliaProduct.assets

        const computedImages = getFormattedImages(algoliaAssets)

        const isDisabled = (skuCode?: string) => {
          const sku =
            clPricesAndStocks?.find(sku => sku.code === skuCode) ?? false
          return !sku || !isInStock(sku)
        }

        const title = algoliaProduct.Name

        const price = (() => {
          const skuPrice = clPricesAndStocks
            ?.find(sku => sku.code === product.value)
            ?.prices?.find(price => price.sku_code === product.value)
          const amountFloat = skuPrice?.amount_float ?? algoliaProduct.FullPrice
          return amountFloat
        })()

        const selectedColor = algoliaProduct.ColorDesc!

        const sizes = algoliaProduct.size

        const sizeList: {
          SKU: string
          sku: string
          size: string
          disabled: boolean
          sizePos: string
          sizeCode: string
        }[] = sizes
          .filter(
            size =>
              typeof size.SKU === 'string' && typeof size.label === 'string'
          )
          .map(size => ({
            sku: size.SKU!,
            SKU: size.SKU!,
            size: size.label ?? '',
            disabled: isDisabled(size.SKU),
            sizePos: size.sizePos ?? '',
            sizeCode: size.sizeCode ?? '',
          }))

        const altColorList = [
          {
            thumbnailUrl: computedImages[0] ?? '',
            color: selectedColor,
            sku: product.value,
            isInStock: isInStock(clPricesAndStocks?.[0]!),
            path: pdpPath(algoliaProduct),
          },
          ...(altColorProducts
            ?.filter(
              (altProduct): altProduct is NonNullable<typeof altProduct> =>
                !!altProduct
            )
            ?.map(altProduct => ({
              thumbnailUrl:
                getFirstMediaByPriority(checkMedia(altProduct))
                  ?.split('/')
                  ?.slice(-1)
                  ?.toString() ?? '',
              color: altProduct.ColorDesc ?? '',
              sku: altProduct.objectID,
              isInStock: clPricesAndStocks?.some(
                el => el.code.includes(product.value) && isInStock(el)
              ),
              path: pdpPath(altProduct),
            })) ?? []),
        ]

        const sizesStocks = algoliaProduct.size.map(size => ({
          sku: size.SKU,
          stock: (size.avRet ?? 0) + (size.avEcom ?? 0),
        }))

        const parseBulletPoints = (description: string) => {
          const bulletPoints = description.split('<br>')
          return bulletPoints
        }
        const productDetail = algoliaProduct.ShortDescription
          ? parseBulletPoints(algoliaProduct.ShortDescription)
          : []
        const sizesArray: string[] = sizeList?.map(size => size.sizeCode) ?? []

        const sizeData = await getSizeData(sizesArray)

        return {
          title,
          price,
          selectedColor,
          sizeList,
          computedImages,
          altColorList,
          sizesStocks,
          productDetail,
          sizes,
          sizeData,
        }
      },
    }
  )

  if (!data.value) {
    throw createError({
      statusCode: 404,
      message: `Product not found`,
    })
  }

  const sizeList = computed(
    () =>
      data.value?.sizeList?.map(size => {
        const itemInCart = lineItems.value.find(
          item => item.sku_code! === size.SKU
        )
        const isAvailable = itemInCart
          ? itemInCart.quantity < (itemInCart.item.inventory?.quantity ?? 0)
          : true

        return {
          ...size,
          disabled: size.disabled || !isAvailable,
        }
      }) ?? []
  )

  return {
    ...toRefs(data as Ref<NonNullable<typeof data.value>>),
    sizeList,
  }
}
